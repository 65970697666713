import React from "react";

import { NavBar } from "../NavBar/index.jsx";
import { menu } from "../dataMenu.js";

import Logo from "../Logo";

import "./style.css";

export const Header = () => {
  const menuTop = [];
  const menuBottom = [];
  for (let item of menu) {
    if (item.level === 1) {
      menuTop.push(item);
    } else {
      menuBottom.push(item);
    }
  }

  return (
    <>
      <div className="header container">
        <NavBar menu={menuTop} />
        <Logo />
        <NavBar menu={menuBottom} />
      </div>
    </>
  );
};
