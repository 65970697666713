import biscuit from "./img/item/Оформление тортов/Акварель/aquarelle5.jpg";
import muss from "./img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы3.jpg";
import bento from "./img/item/Оформление тортов/Бенто/bento33.jpg";
import tarts from "./img/item/Тарты/Фисташка-Клубника.jpg";
import cakes from "./img/item/Мусовые пироженые/Экзотик.jpg";
import makarons from "./img/item/Макаронсы/Лайм-Мята.jpg";
import zefir from "./img/item/Зефиры, Безе/Безе Павлова.jpg";
import multilevel from "./img/item/Торты на свадьбу и юбилей/Свадебный2.jpg";
import ponchiki from "./img/item/Тортопончики/Красный.jpg"
import candy from "./img/item/Конфеты/Конфеты.jpg"

// Главная страница
export const mainPage = [
  {
    id: "/tort",
    href: "/biscuit",
    nameProduct: "Бисквитные торты",
    fullName: "Бисквитные торты",
    img: biscuit,
  },
  {
    id: "/tort",
    href: "/muss",
    nameProduct: "Муссовые торты",
    fullName: "Муссовые торты",
    img: muss,
  },
  {
    id: "/tort",
    href: "/bento",
    nameProduct: "Бенто торты",
    fullName: "Бенто торты",
    img: bento,
  },
  {
    id: "/tort",
    href: "/multi",
    nameProduct: "Торты многоярусные",
    fullName: "Торты многоярусные",
    img: multilevel,
  },
  {
    id: "/tarts",
    href: "/tarts",
    nameProduct: "Тарты",
    fullName: "Тарты",
    img: tarts,
  },
  {
    id: "/cakes",
    href: "/cakes",
    nameProduct: "Муссовые пироженные",
    fullName: "Муссовые пироженные",
    img: cakes,
  },
  {
    id: "/makarons",
    href: "/makarons",
    nameProduct: "Макаронсы",
    fullName: "Макаронсы",
    img: makarons,
  },
  {
    id: "/zefir",
    href: "/zefir",
    nameProduct: "Зефир и Безе",
    fullName: "Зефир и Безе",
    img: zefir,
  },
  {
    id: "/ponchiki",
    href: "/ponchiki",
    nameProduct: "Тортопончики",
    fullName: "Тортопончики",
    img: ponchiki,
  },
  {
    id: "/candy",
    href: "/candy",
    nameProduct: "Конфеты",
    fullName: "Конфеты",
    img: candy,
  },
];
