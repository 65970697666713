import Footer from "../../Footer";
import "./style.css";

const AgreementPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className="container wrap">
      <section className="agreement">
        <div className="agreement__wrap">
          <h1>Сведения о персональной информации</h1>
          <p>
            Отправляя сведения через электронные формы на сайте giocondi.ru, Вы
            даете согласие на обработку предоставленной Вами информации (включая
            персональные данные) и иные действия с ней на указанных ниже
            условиях. Настоящим я, своей волей и в своем интересе, даю согласие
            на обработку и осуществление иных действий (далее – обработка) с
            предоставленной мною информацией, в том числе с персональными
            данными, (далее – Предоставленная информация). Согласие на обработку
            Предоставленной информации дается мною для целей рассмотрения моих
            заказов и обращений, принятия решений по ним; информирования меня о
            принятых решениях; реализации (предоставления, продвижения) мне
            товаров, работ, услуг; предоставления мне информации; проведения
            маркетинговых исследований, получения информационной e-mail
            рассылки, sms-информирования, статистических целей. Кондитерская
            "ДжоКонди" вправе осуществлять обработку Предоставленной информации,
            в том числе осуществлять с Предоставленной информацией следующие
            действия (операции) или совокупность: сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение. Кондитерская
            "ДжоКонди" вправе осуществлять обработку всей Предоставленной
            информации, переданной мною посредством заполнения любой формы на
            Интернет-сайте, в процессе передачи которой я даю настоящее
            Согласие. Оператор вправе осуществлять обработку Предоставленной
            информации любыми способами, как с использованием, так и без
            использования средств автоматизации. Выражаю свое согласие на
            трансграничную передачу моих персональных данных.
          </p>
          <h1>О продукции</h1>
          <p>
            Внимание: изготавливаемая нами продукция хранится от 6 до 72 часов.
            В некоторых случаях продукт может содержать конструктивные,
            несъедобные составляющие (декор, оформление и пр.), которые
            необходимо снять перед употреблением!
          </p>
          <p>
            Ввиду того, что изготовление продукции осуществляется вручную - вес,
            окраска, иные особенности оформления (декор, надписи и пр.) могут
            отличаться от оригинала/образца. В связи с этим, претензии не
            принимаются.
          </p>
          <p>
            Вы подтверждаете, что надлежащим образом ознакомлены, согласны и
            осознаёте в полной мере, что за продукт, произведенный по Вашему
            заказу (образцу/макету), который может затронуть права третьих лиц
            (включая авторские), Вы несёте самостоятельно ответственность в
            порядке, предусмотренном действующим законодательством. Настоящее
            согласие действует бессрочно.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AgreementPage;
