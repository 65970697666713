//Оформление фигурками ручной работы
import hand1 from "../img/item/Оформление тортов/Ручная работа/hand1.jpg";
import hand2 from "../img/item/Оформление тортов/Ручная работа/hand2.jpg";
import hand3 from "../img/item/Оформление тортов/Ручная работа/hand3.jpg";
import hand4 from "../img/item/Оформление тортов/Ручная работа/hand4.jpg";

export const handDecor = [
  {
    name: `Оформление торта фигурками ручной работы"`,
    descDecor: 'поз. 1',
    img: hand1,
  },
  {
    name: `Оформление торта фигурками ручной работы"`,
    descDecor: 'поз. 2',
    img: hand2,
  },
  {
    name: `Оформление торта фигурками ручной работы"`,
    descDecor: 'поз. 3',
    img: hand3,
  },
  {
    name: `Оформление торта фигурками ручной работы"`,
    descDecor: 'поз. 4',
    img: hand4,
  },
];