import React from "react";
import Footer from "../../../Footer";
import BigImg from "../../BigImg";
import "./style.css";

const DecorList = ({ decorItem }) => {
  const [openImgBig, setOpenImgBig] = React.useState(false);
  const [imgBig, setImgBig] = React.useState('');

  const handleOpenImgBig = (img) => {
    setOpenImgBig(true);
    setImgBig(img);
  };

   return (
    <div className="container wrap">
      <section className="decor__item">
        {decorItem.map((decor, i) => {
          return (
            <div
              className="decor__card"
              key={i}
              onClick={() => handleOpenImgBig(decor.img)}
            >
              <img className="decor__img" src={decor.img} alt={decor.name} />
              <div className="decorList">
                <h1>{decor.name}</h1>
                <p>{decor.descDecor}</p>
              </div>
            </div>
          );
        })}
        {openImgBig && <BigImg imgBig = {imgBig} setOpenImgBig={setOpenImgBig}/>}
      </section>
      <Footer />
    </div>
  );
};

export default DecorList;
