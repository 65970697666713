import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuActive: JSON.parse(localStorage.getItem('menuActive')) || "/",
};

export const menuActiveSlice = createSlice({
  name: "menuActive",
  initialState,
  reducers: {
    setMenuActive(state, action) {
      state.menuActive = action.payload;
      localStorage.setItem('menuActive', JSON.stringify(state.menuActive))
    },
  },
});

export const { setMenuActive } = menuActiveSlice.actions;
export default menuActiveSlice.reducer
