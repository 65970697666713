import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuActive } from "../redux/slices/menuActiveSlice";
import { NavBarDroop } from "../NavBarDroop";

import "./style.css";

export const NavBarItems = ({ item }) => {
  const dispatch = useDispatch();
  const menuActive = useSelector((state) => state.menuActive.menuActive);

  const [droopMenu, setDroopMenu] = React.useState(false);

  const clickMenuDroop = (e)=> {
    e.stopPropagation()
    dispatch(setMenuActive(href))
  }

  const { nameMenu, subMenu, href } = item;
  return (
    <>
      {subMenu ? (
        <button
          className={
            menuActive === href ? "menu__link menu__link-active" : "menu__link"
          }
          onClick={() => (e) => clickMenuDroop(e)}
          onMouseEnter={() => setDroopMenu(true)}
          onMouseLeave={() => setDroopMenu(false)}
        >
          {nameMenu}
          {droopMenu && <NavBarDroop subMenu={subMenu} />}
        </button>
      ) : (
        <Link
          to={href}
          className={
            menuActive === href ? "menu__link menu__link-active" : "menu__link"
          }
          onClick={(e) => clickMenuDroop(e)}
        >
          {nameMenu}
        </Link>
      )}
    </>
  );
};
