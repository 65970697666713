import { Link } from "react-router-dom";
import "./style.css";

export const DecorProduct = ({ item }) => {
  window.scrollTo(0, 0);
  return (
      <Link to={item.path} className="decor__card">
        <img className="decor__img" src={item.imgDecor} alt={item.nameDecor} />
        <div>
          <p className="decor__title">{item.nameDecor}</p>
          <p className="decor__title">{item.descDecor}</p>
        </div>
        <div className="decoration__price">{item.priceDecor}</div>
      </Link>
  );
};
