//Рисунком в акварельной технике
import aquarelle1 from "../img/item/Оформление тортов/Акварель/aquarelle1.jpg";
import aquarelle2 from "../img/item/Оформление тортов/Акварель/aquarelle2.jpg";
import aquarelle3 from "../img/item/Оформление тортов/Акварель/aquarelle3.jpg";
import aquarelle4 from "../img/item/Оформление тортов/Акварель/aquarelle4.jpg";
import aquarelle5 from "../img/item/Оформление тортов/Акварель/aquarelle5.jpg";
import aquarelle6 from "../img/item/Оформление тортов/Акварель/aquarelle6.jpg";
import aquarelle7 from "../img/item/Оформление тортов/Акварель/aquarelle7.jpg";
import aquarelle8 from "../img/item/Оформление тортов/Акварель/aquarelle8.jpg";
import aquarelle9 from "../img/item/Оформление тортов/Акварель/aquarelle9.jpg";
import aquarelle10 from "../img/item/Оформление тортов/Акварель/aquarelle10.jpg";
import aquarelle11 from "../img/item/Оформление тортов/Акварель/aquarelle11.jpg";
import aquarelle12 from "../img/item/Оформление тортов/Акварель/aquarelle12.jpg";
import aquarelle13 from "../img/item/Оформление тортов/Акварель/aquarelle13.jpg";


export const aquarelleDecor = [
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 1',
    img: aquarelle1,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 2',
    img: aquarelle2,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 3',
    img: aquarelle3,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 4',
    img: aquarelle4,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 5',
    img: aquarelle5,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 6',
    img: aquarelle6,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 7',
    img: aquarelle7,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 8',
    img: aquarelle8,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 9',
    img: aquarelle9,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 10',
    img: aquarelle10,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 11',
    img: aquarelle11,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 12',
    img: aquarelle12,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 13',
    img: aquarelle13,
  },
];