import lambet2 from "../img/item/Оформление тортов/Базовое/Ламбет2.jpg";
import rome7 from "../img/item/Оформление тортов/Леденцами/Роме 7.jpg";
import gimnast from "../img/item/Оформление тортов/Шоколадными шариками/Гимнастка16.jpg";
import flowers2 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы2.jpg";
import hard4 from "../img/item/Оформление тортов/Сложный декор/hard4.jpg";
import aquarelle1 from "../img/item/Оформление тортов/Акварель/aquarelle1.jpg";
import berry1 from "../img/item/Оформление тортов/Ягодами/berry1.jpg";
import hand2 from "../img/item/Оформление тортов/Ручная работа/hand2.jpg";
import cake1 from "../img/item/Оформление тортов/Пряниками/cake1.jpg";
import bento33 from '../img/item/Оформление тортов/Бенто/bento33.jpg'
import painting2 from "../img/item/Оформление тортов/Роспись/painting2.jpg";
import sugar7 from "../img/item/Оформление тортов/На сахарной бумаге/sugar7.jpg";


export const decorationList = [
  {
    id: "decor1",
    nameDecor: "Базовое оформление",
    descDecor: null,
    imgDecor: lambet2,
    priceDecor: "Входит в стоимость торта",
    path:'/decor/basic',
  },
  {
    id: "decor2",
    nameDecor: "Оформление торта",
    descDecor: "леденцами из изомальта",
    imgDecor: rome7,
    priceDecor: "Цена от 200 руб.",
     path:'/decor/isomalt',
  },
  {
    id: "decor3",
    nameDecor: "Оформление торта",
    descDecor: "шоколадными шариками",
    imgDecor: gimnast,
    priceDecor: "Цена от 200 руб.",
     path:'/decor/ball',
  },
  {
    id: "decor4",
    nameDecor: "Оформление торта",
    descDecor: "цветами из вафельной бумаги",
    imgDecor: flowers2,
    priceDecor: "Цена 500 руб.",
    path:'/decor/flowers',
  },
  {
    id: "decor5",
    nameDecor: "Сложный декор",
    descDecor: null,
    imgDecor: hard4,
    priceDecor: "Цена 1000 руб.",
    path:'/decor/hard',
  },
  {
    id: "decor6",
    nameDecor: "Роспись торта",
    descDecor: null,
    imgDecor: painting2,
    priceDecor: "Цена 700 руб.",
    path:'/decor/painting',
  },
  {
    id: "decor7",
    nameDecor: "Оформление торта",
    descDecor: "рисунком в акварельной технике",
    imgDecor: aquarelle1,
    priceDecor: "Цена 500 руб.",
    path:'/decor/aquarelle',
  },
  {
    id: "decor8",
    nameDecor: "Оформление торта",
    descDecor: "свежими ягодами",
    imgDecor: berry1,
    priceDecor: "Цена от 200 руб. за 1 вид ягод",
    path:'/decor/berry',
  },
  {
    id: "decor9",
    nameDecor: "Оформление торта",
    descDecor: "фигурками ручной работы",
    imgDecor: hand2,
    priceDecor: "Цена от 300 руб.",
    path:'/decor/hand',
  },
  {
    id: "decor10",
    nameDecor: "Оформление торта",
    descDecor: "фигурками из пряника",
    imgDecor: cake1,
    priceDecor: "Цена 600 руб.",
    path:'/decor/cake',
  },
  {
    id: "decor11",
    nameDecor: "Оформление торта",
    descDecor: "картинками на сахарной бумаге",
    imgDecor: sugar7,
    priceDecor: "Цена 350 руб.",
    path:'/decor/sugar',
  },
  {
    id: "decor12",
    nameDecor: "Оформление бенто торта",
    imgDecor: bento33,
    priceDecor: "Входит в стоимость торта",
    path:'/decor/bento',
  },
];
