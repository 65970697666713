import React from "react";
import DatePicker from "react-datepicker"; // Библиотека формы для даты
import "react-datepicker/dist/react-datepicker.css";

import "./style.css";

const PopapForm = ({
  check,
  item,
  popap,
  setMessagePost,
  setPopap,
  setUserName,
  userName,
  totalPrice,
  delivery,
  setSending,
}) => {
  const popapRef = React.useRef(null);
  const [startDate, setStartDate] = React.useState(null);
  const [mobile, setMobile] = React.useState("");
  const [file, setFile] = React.useState({});
  const [error, setError] = React.useState([]);
  const [imgSrc, setImgSrc] = React.useState('');

  // Закрытие формы ввода --------------------------------------------------------------------------
  React.useEffect(() => {
    if (!popap) return;

    // Закрытие модального окна по клику вне окна
    const handleClickBtnPopap = (event) => {
      if (!popapRef.current.contains(event.target)) setPopap(false);
    };

    // Закрытие модального окна по нажатию клавиши "Escape"
    const handleKeyEscapePopap = (event) => {
      if (event.key === "Escape") setPopap(false);
    };

    // Установка прослушивателей
    document.addEventListener("keydown", handleKeyEscapePopap);
    document
      .querySelector(".basket__popap")
      .addEventListener("click", handleClickBtnPopap);

    // Удаление прослушивателей
    return () => {
      document
        .querySelector(".basket__popap")
        .removeEventListener("click", handleClickBtnPopap);
      document.removeEventListener("keydown", handleKeyEscapePopap);
    };
  });

  //Добавление превью загружаемой картинки
  function handleFileSelect(e) {
    setError([])
    setFile(e.target.files[0]);
    if(e.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => setImgSrc(e.target.result);
    } else setImgSrc('')
  }

  //Отправка формы на почту---------------------------------------------------------------------------
  const dataOrder = startDate ? startDate.toLocaleDateString() : ""; // Перевод даты в строчный вид dd.mm.yyyy

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    const form = new FormData(e.target);
    const itemform = JSON.stringify({
      dataOrder,
      item,
      check,
      totalPrice,
      delivery,
    });

    form.append("itemform", itemform);
    // const formObject = Object.fromEntries(form.entries());

    const response = await fetch("./php/main.php", {
      method: "POST",
      body: form,
    });
    setSending(false);
    if (response.ok) {
      const res = await response.json();
      if (res.result) {
        setPopap(false);
        setMessagePost(res.result);
      }
      else setError(res);
    } else console.log("Ошибка: " + response.status);

    // const TELEGRAM_BOT_TOKEN = "6786277575:AAFxJ9vDB_FAlThBrcODyWdssUpMjmGcJow";
    // const TELEGRAM_CHAT_ID = "-1002249627933";
    // const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    // const textTelegramm = `Поступила заявка с сайта!`;
    // try {
    //   const responseTel = await fetch(API, {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       chat_ID: TELEGRAM_CHAT_ID,
    //       textTelegramm,
    //     }),
    //   });
    //   if (responseTel.ok) {
    //     console.log("Сообщение в Телеграмм отправлено");
    //   } else {
    //     throw new Error(responseTel.statusText);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  return (
    <form
      ref={popapRef}
      className="popap__form"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      <div className="input__wrap">
        <label className="input__label">
          Имя<span>*</span>
        </label>
        <input
          className="input"
          id="name"
          name="userName"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          required
        />
        {error.nameError && (
          <label className="error__label" id="error__name">
            {error.nameError}
          </label>
        )}
      </div>
      <div className="input__wrap">
        <label className="input__label">
          Телефон<span>*</span>
        </label>
        <input
          className="input"
          id="mobile"
          name="mobile"
          placeholder="8 999 999 99 99"
          type="tel"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
        {error.telError && (
          <label className="error__label" id="error__name">
            {error.telError}
          </label>
        )}
      </div>
      <div className="input__wrap">
        <label className="input__label">
          Введите дату выполнения заказа<span>*</span>
        </label>
        <DatePicker
          wrapperClassName="input"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd.MM.yyyy"
          minDate={new Date()}
          required
        />
      </div>
      <div className="input__wrap">
        <label className="input__label">Сообщение</label>
        <textarea
          className="input message"
          id="text"
          name="text"
          placeholder=""
          // onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="input__wrap">
        <label htmlFor="file" className="input__file_label">
          Приложите эскиз оформления (jpg или png)
        </label>
        <input
          className="input__file"
          id="file"
          type="file"
          name="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handleFileSelect(e)}
        />
        {imgSrc && (<div className="imgfile">
          <img src={imgSrc} alt=''></img>
        </div>)}
        <label htmlFor="file" className="input__file_costom">
          {(file ? file.name : "Файл не выбран") || "Файл не выбран"}
        </label>
        {error.fileError && (
          <label htmlFor="file" className="error__label" id="error__file">
            {error.fileError}
          </label>
        )}
      </div>
      <button className="popap__btn">Отправить</button>
    </form>
  );
};

export default PopapForm;
