//Базовое оформление
import lambet1 from "../img/item/Оформление тортов/Базовое/Ламбет1.jpg";
import lambet2 from "../img/item/Оформление тортов/Базовое/Ламбет2.jpg";
import lambet3 from "../img/item/Оформление тортов/Базовое/Ламбет3.jpg";
import lambet4 from "../img/item/Оформление тортов/Базовое/Ламбет4.jpg";
import lambet5 from "../img/item/Оформление тортов/Базовое/Ламбет5.jpg";
import lambet6 from "../img/item/Оформление тортов/Базовое/Ламбет6.jpg";
import happy_birthday from "../img/item/Оформление тортов/Базовое/Happy_Birthday.jpg";
import wow from "../img/item/Оформление тортов/Базовое/Wow.jpg";
import girl_70 from "../img/item/Оформление тортов/Базовое/Девчуле_70.jpg";
import kaktus from "../img/item/Оформление тортов/Базовое/Кактус.jpg";
import dr_misha from "../img/item/Оформление тортов/Базовое/С ДР Миша.jpg";
import dr_love from "../img/item/Оформление тортов/Базовое/С ДР любимый.jpg";
import skitls from "../img/item/Оформление тортов/Базовое/Скитлс.jpg";
import sofi_10 from "../img/item/Оформление тортов/Базовое/Софье_10.jpg";
import shokolat_note from "../img/item/Оформление тортов/Базовое/Шоколад_ноты.jpg";
import shokolat_pipe from "../img/item/Оформление тортов/Базовое/Шоколад_трубки.jpg";
import jubilee_60 from "../img/item/Оформление тортов/Базовое/Юбилей_60.jpg";

export const basicDecor = [
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 1',
    img: lambet1,
  },
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 2',
    img: lambet2,
  },
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 3',
    img: lambet3,
  },
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 4',
    img: lambet4,
  },
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 5',
    img: lambet5,
  },
  {
    name: `Базовое оформление "Ламбет"`,
    descDecor: 'поз. 6',
    img: lambet6,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 7',
    img: happy_birthday,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 8',
    img: wow,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 9',
    img: girl_70,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 10',
    img: kaktus,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 11',
    img: dr_misha,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 12',
    img: dr_love,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 13',
    img: skitls,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 14',
    img: sofi_10,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 15',
    img: shokolat_note,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 16',
    img: shokolat_pipe,
  },
  {
    name: `Базовое оформление`,
    descDecor: 'поз. 17',
    img: jubilee_60,
  },
];