import React from "react";
import './style.css'

const BigImg = ({ imgBig, setOpenImgBig}) => {
   const [openImg, setOpenImg] = React.useState(false)

  React.useEffect(() => {

    const handleCloseImgBig = () => {
        if(!openImg) return;
        setOpenImgBig(false)
    };
    setOpenImg(true)
    document.addEventListener("click", handleCloseImgBig);
    return () => document.removeEventListener("click", handleCloseImgBig);
  },[openImg, setOpenImgBig] );

  return (
    <div className="img-big__wrap">
      <div className="img-big">
        <img src={imgBig} alt='фото' />
      </div>
    </div>
  );
};

export default BigImg;
