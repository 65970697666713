import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchValue: "",
  toggleSearch: false,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setToggleSearch(state, action) {
      state.toggleSearch = action.payload;
    },
  },
});

export const { setSearchValue, setToggleSearch } = searchSlice.actions;
export default searchSlice.reducer;
