//Сложный декор
import hard1 from "../img/item/Оформление тортов/Сложный декор/hard1.jpg";
import hard2 from "../img/item/Оформление тортов/Сложный декор/hard2.jpg";
import hard3 from "../img/item/Оформление тортов/Сложный декор/hard3.jpg";
import hard4 from "../img/item/Оформление тортов/Сложный декор/hard4.jpg";
import hard5 from "../img/item/Оформление тортов/Сложный декор/hard5.jpg";

export const hardDecor = [
  {
    name: `Сложный декор`,
    descDecor: 'поз. 1',
    img: hard1,
  },
  {
    name: `Сложный декор`,
    descDecor: 'поз. 2',
    img: hard2,
  },
  {
    name: `Сложный декор`,
    descDecor: 'поз. 3',
    img: hard3,
  },
  {
    name: `Сложный декор`,
    descDecor: 'поз. 4',
    img: hard4,
  },
  {
    name: `Сложный декор`,
    descDecor: 'поз. 5',
    img: hard5,
  },
];