//Страница с бенто-тортами

import laim from './img/item/Бенто/Клубника-Лайм.jpg'
import kokos from './img/item/Бенто/Кокос-Вишня.jpg'
import mak_smorodina from './img/item/Бенто/Мак-Смородина.jpg'
import latte from './img/item/Бенто/Ореховое латте.jpg'
import yabloko_pryan from './img/item/Бенто/Пряное яблоко.jpg'
import shokolad_apelsin from './img/item/Бенто/Шоколад-Апельсин.jpg'

export const bentoList = {
    productList: [
        {
          id: "bnt1",
          groupProduct: "Бенто-торт",
          nameProduct: '"Клубника-Лайм"',
          img: laim,
          desc: [
            "Лаймовый мусс с кусочками желе",
            "Маковый бисквит",
            "Ванильный крем с цедрой лайма",
            "Ванильный бисквит",
          ],
          price: 1700,
          unit: "шт.",
          minCount: 1, // Минимальное кол-во
          stepCount: 1, // Шаг прибавления
          discount: 0, //Скидка в %
        },
        {
            id: "bnt2",
            groupProduct: "Бенто-торт",
            nameProduct: '"Кокос-Вишня"',
            img: kokos,
            desc: [
              "Кокосовый мусс с вишней",
              "Ванильный бисквит",
              "Кокосовый крем",
            ],
            price: 1700,
            unit: "шт.",
            minCount: 1, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "bnt3",
            groupProduct: "Бенто-торт",
            nameProduct: '"Мак-Смородина"',
            img: mak_smorodina,
            desc: [
              "Лимонный мусс с цедрой",
              "Ягоды смородины",
              "Маковый бисквит",
              "Смородиновый крем",
            ],
            price: 1700,
            unit: "шт.",
            minCount: 1, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "bnt4",
            groupProduct: "Бенто-торт",
            nameProduct: '"Ореховое латте"',
            img: latte,
            desc: [
              "Кофейный мусс с кусочками измельченного шоколада",
              "Шоколадный бисквит",
              "Ванильный крем",
              "Ореховый бисквит",
            ],
            price: 1700,
            unit: "шт.",
            minCount: 1, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "bnt5",
            groupProduct: "Бенто-торт",
            nameProduct: '"Пряное яблоко"',
            img: yabloko_pryan,
            desc: [
              "Ореховый бисквит",
              "Ванильный крем",
              "Карамелизированные яблоки с корицей",
            ],
            price: 1700,
            unit: "шт.",
            minCount: 1, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "bnt6",
            groupProduct: "Бенто-торт",
            nameProduct: '"Шоколад-апельсин"',
            img: shokolad_apelsin,
            desc: [
              "Шоколадный бисквит",
              "Шоколадный крем с цедрой апельсина",
              "Апельсиновый слой с кусочками апельсина",
            ],
            price: 1700,
            unit: "шт.",
            minCount: 1, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
]}