import { configureStore } from "@reduxjs/toolkit";
import menuActiveSlice from "./slices/menuActiveSlice";
import searchSlice from "./slices/searchSlice";
import basketSlice from "./slices/basketSlice";

export const store = configureStore({
  reducer: {
    menuActive: menuActiveSlice,
    searchValue: searchSlice,
    item: basketSlice,
  },
});
