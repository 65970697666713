import "./style.css";
import Footer from "../../../Footer";
import { DecorProduct } from "../DecorProduct";
import { decorationList } from "../../../dataDecor/mainDecor";

export const DecorPage = () => {
  return (
    <div className="container wrap">
      <section className="decor__item">
        {decorationList.map((item) => (
          <DecorProduct item={item} key={item.id} />
        ))}
      </section>
      <Footer />
    </div>
  );
};
