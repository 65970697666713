import implosive_karamel from "./img/item/Тарты/Взрывная карамель.jpg"
import grusha from "./img/item/Тарты/Грушевый.jpg"
import kedr from "./img/item/Тарты/Кедровый орех.jpg"
import klubnika_kokos from "./img/item/Тарты/Клубника-Кокос.jpg"
import kokos_karamel from "./img/item/Тарты/Кокос-Карамель.jpg"
import kofe_Beilis from "./img/item/Тарты/Кофе Бейлис.jpg"
import mak from "./img/item/Тарты/Маковый.jpg"
import malina from "./img/item/Тарты/Малиновый.jpg"
import morkov from "./img/item/Тарты/Морковный.jpg"
import smorodina from "./img/item/Тарты/Смородиновый взрыв.jpg"
import snikers from "./img/item/Тарты/Сникерс.jpg"
import sirno_smorodina from "./img/item/Тарты/Сырно-смородиновый.jpg"
import chernika_morogenoe from "./img/item/Тарты/Черничное мороженое.jpg"
import clubnika_vanil from "./img/item/Тарты/Клубника-Ваниль.jpg"
import shokolad_karamel from "./img/item/Тарты/Шоколадно-карамельный.jpg"
import fistashka_klubnika from "./img/item/Тарты/Фисташка-Клубника.jpg"
import diabella from "./img/item/Тарты/Диабелла.jpg"
import tart_rafaello from "./img/item/Тарты/Рафаэлло.jpg"
import limon_merenga from "./img/item/Тарты/Лимонный с меренгой.jpg"
import limon from "./img/item/Тарты/Лимонный.jpg"
import kofe_Brauni from "./img/item/Тарты/Кофе-Брауни.jpg"
import mango_marakuja from "./img/item/Тарты/Манго маракуйя.jpg"
import vanil from "./img/item/Тарты/Ванильный.jpg"

export const tartsList = {
  productList: [
    {
      id: "tart1",
      groupProduct: "Тарт",
      nameProduct: '"Взрывная карамель"',
      img: implosive_karamel,
      desc: [
        "Фундучное сабле",
        "Взрывная карамель",
        "Карамелизированный грецкий орех",
        "Сливочная карамель",
      ],
      price: 270,
      unit: "шт.",
      minCount: 4, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "tart2",
      groupProduct: "Тарт",
      nameProduct: '"Грушевый"',
      img: grusha,
      desc: ["Фундучное сабле", "Компоте груша"],
      price: 190,
      unit: "шт.",
      minCount: 4, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "tart3",
      groupProduct: "Тарт",
      nameProduct: '"Кедровый орех"',
      img: kedr,
      desc: [
        "Песочное сабле",
        "Франжипан с кедровым орехом",
        "Карамельная намелака",
        "Пралине из кедрового ореха",
        "Карамельный центр с пралине",
        "Взбитый карамельный ганаш",
      ],
      price: 470,
      unit: "шт.",
      minCount: 4, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
        id: "tart4",
        groupProduct: "Тарт",
        nameProduct: '"Клубника-Кокос"',
        img: klubnika_kokos,
        desc: [
          "Песочное сабле",
          "Карамелизированный пекан",
          "Кокосовый мусс",
          "Компоте клубника",
          "Клубничный ганаш",
        ],
        price: 375,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart5",
        groupProduct: "Тарт",
        nameProduct: '"Кокос-Карамель"',
        img: kokos_karamel,
        desc: [
          "Песочное сабле",
          "Хрустящий слой с миндальными лепестками",
          "Кокосовая карамель",
          "Кокосовое кремю",
          "Взбитый кокосовый ганаш",
        ],
        price: 470,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart6",
        groupProduct: "Тарт",
        nameProduct: '"Кофе Бейлис"',
        img: kofe_Beilis,
        desc: [
          "Шоколадное сабле",
          "Карамелизированные орехи",
          "Кофейный ганаш с “Бейлис”",
          "Шоколадный мусс с “Бейлис”",
          "Взбитый кофейный ганаш",
        ],
        price: 335,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart7",
        groupProduct: "Тарт",
        nameProduct: '"Маковый"',
        img: mak,
        desc: [
          "Миндальное сабле с маком",
          "Маковый франжипан",
          "Лимонный ганаш",
          "Лимонная миренда",
        ],
        price: 250,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart8",
        groupProduct: "Тарт",
        nameProduct: '"Малиновый"',
        img: malina,
        desc: [
          "Песочное сабле",
          "Хрусящий слой",
          "Лёгкий мусс",
          "Ягодный ганаш",
        ],
        price: 400,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart9",
        groupProduct: "Тарт",
        nameProduct: '"Морковный"',
        img: morkov,
        desc: [
          "Имбирное сабле",
          "Морковный бисквит",
          "Карамельный ганаш с “Бейлис”",
          "Взбитый ганаш с маскарпоне",
        ],
        price: 290,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart10",
        groupProduct: "Тарт",
        nameProduct: '"Смородиновый взрыв"',
        img: smorodina,
        desc: [
          "Миндально-лимонное сабле",
          "Хрустящий слой с взрывной карамелью",
          "Лимонное кремю",
          "Взбитый смородиновый ганаш",
        ],
        price: 430,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart11",
        groupProduct: "Тарт",
        nameProduct: '"Сникерс"',
        img: snikers,
        desc: [
          "Шоколадное сабле",
          "Хрустящий слой",
          "Сливочная карамель",
          "Лёгкий мусс",
          "Шоколадная глазурь",
          "Взбитый шоколадный ганаш",
        ],
        price: 320,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart12",
        groupProduct: "Тарт",
        nameProduct: '"Сырно-смородиновый"',
        img: sirno_smorodina,
        desc: [
          "Песочное сабле",
          "Холодный ягодный чизкейк",
          "Кули смородина",
        ],
        price: 200,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart13",
        groupProduct: "Тарт",
        nameProduct: '"Черничное мороженое"',
        img: chernika_morogenoe,
        desc: [
          "Миндальное сабле",
          "Франжипан с черникой",
          "Шоколадный ганаш с черникой",
          "Черничная намелака",
        ],
        price: 340,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart14",
        groupProduct: "Тарт",
        nameProduct: '"Клубника-Ваниль"',
        img: clubnika_vanil,
        desc: [
          "Миндальное сабле",
          "Хрустящий слой",
          "Клубничный соус",
          "Клубничный ганаш",
          "Бисквит без сифона",
          "Ванильная намелака",
        ],
        price: 200,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart15",
        groupProduct: "Тарт",
        nameProduct: '"Шоколадно-карамельный"',
        img: shokolad_karamel,
        desc: [
          "Шоколадное сабле",
          "Взрывная карамель",
          "Карамелизированные орехи",
          "Шоколадная карамель",
        ],
        price: 290,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart16",
        groupProduct: "Тарт",
        nameProduct: '"Фисташка-Клубника"',
        img: fistashka_klubnika,
        desc: [
          "Песочное сабле",
          "Фисташковый бисквит “Джоконда”",
          "Кули клубника",
          "Фисташковый ганаш",
        ],
        price: 340,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart17",
        groupProduct: "Тарт",
        nameProduct: '"Диабелла"',
        img: diabella,
        desc: [
          "Фундучное сабле",
          "Шоколадный бисквит",
          "Шоколадный мусс с фундучной пастой",
        ],
        price: 310,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart18",
        groupProduct: "Тарт",
        nameProduct: '"Рафаэлло"',
        img: tart_rafaello,
        desc: [
          "Песочное сабле",
          "Карамелизированный миндаль",
          "Кокосовое креме",
        ],
        price: 230,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart19",
        groupProduct: "Тарт",
        nameProduct: '"Лимонный с меренгой"',
        img: limon_merenga,
        desc: [
          "Миндально-лимонное сабле",
          "Лимонный крем",
          "Итальянская меренга",
        ],
        price: 170,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
      {
        id: "tart20",
        groupProduct: "Тарт",
        nameProduct: '"Лимонный"',
        img: limon,
        desc: [
          "Песочное сабле",
          "Лимонное креме",
        ],
        price: 130,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart21",
        groupProduct: "Тарт",
        nameProduct: '"Кофе-Брауни"',
        img: kofe_Brauni,
        desc: [
          "Шоколадно-кофейное сабле",
          "Брауни с грецким орехом",
          "Взбитый шоколадный ганаш",
        ],
        price: 290,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart22",
        groupProduct: "Тарт",
        nameProduct: '"Манго-маракуйя"',
        img: mango_marakuja,
        desc: [
          "Песочное сабле",
          "Компоте манго-маракуйя",
        ],
        price: 200,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "tart23",
        groupProduct: "Тарт",
        nameProduct: '"Ванильный"',
        img: vanil,
        desc: [
          "Песочное сабле",
          "Ванильная намелака",
        ],
        price: 250,
        unit: "шт.",
        minCount: 4, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount:0, //Скидка в %
        hit: true,
      },
  ],
};
