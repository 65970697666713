import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenuActive } from "../redux/slices/menuActiveSlice";

import "./style.css";

const NavBarDroopItems = ({ item }) => {
  const dispatch = useDispatch();
  const { id, nameMenu, href } = item;

  return (
    <li key={id} onClick={() => dispatch(setMenuActive(href))}>
      <Link to={href} className="droop-menu__link">
        {nameMenu}
      </Link>
    </li>
  );
};

export const NavBarDroop = ({ subMenu }) => {
  return (
    <ul className="droop-menu">
      {subMenu.map((item) => (
        <NavBarDroopItems key={item.id} item={item} />
      ))}
    </ul>
  );
};
