import React from "react";
import { useDispatch } from "react-redux";
import { addItem, deleteItem } from "../../redux/slices/basketSlice";
import BigImg from "../BigImg";

import "./style.css";

export const Product = ({ product, decorList }) => {
  const {
    id,
    anot,
    desc,
    price,
    unit,
    nameProduct,
    img,
    groupProduct,
    minCount,
    stepCount,
    discount,
    hit,
  } = product;

  const [openImgBig, setOpenImgBig] = React.useState(false);
  const [imgBig, setImgBig] = React.useState("");

  const handleOpenImgBig = (img) => {
    setOpenImgBig(true);
    setImgBig(img);
  };

  const dispatch = useDispatch();
  const [toggle, setToggle] = React.useState(
    localStorage.getItem(`toggle ${id}`) || false
  );

  const onClickChange = () => {
    if (!toggle) {
      const item = {
        id,
        nameProduct,
        img,
        price: price - (price * discount) / 100,
        unit,
        groupProduct,
        minCount,
        stepCount,
        decorList,
        discount,
        decor: [],
        priceDecor: 0,
      };

      dispatch(addItem(item));
      setToggle(true);
    } else {
      dispatch(deleteItem(id));
      setToggle(false);
    }
  };

  const anotLink = [];
  if (anot) {
    for (let i of anot) anotLink.push(<li key={i}>{i}</li>);
  }

  const descLink = [];
  if (desc) {
    for (let i of desc) descLink.push(<li key={i}>{i}</li>);
  }

  return (
    <>
      <div className="item__card" key={id}>
        <div className="item__img" onClick={() => handleOpenImgBig(img)}>
          <img src={img} alt={nameProduct} />
        </div>
        <h2 className="item__title">
          {groupProduct} <br /> {nameProduct}
        </h2>
        {anot && <ol className="item__anot">{anotLink}</ol>}
        {desc && <ol className="item__desc"> {descLink} </ol>}
        {price && (
          <div>
            <div className="item__price">
              Цена {price} ₽. за {unit}
            </div>
            <button
              id={id}
              className={toggle ? "item__btn-del" : "item__btn-add"}
              onClick={onClickChange}
            >
              {toggle ? "Удалить из корзины" : "Добавить в корзину"}
            </button>
          </div>
        )}
        {discount > 0 ? (
          <div className="item__discount">-{discount}%</div>
        ) : null}
        {hit && (
          <div className="item__hit">Хит</div>
        )}
      </div>
      {openImgBig && <BigImg imgBig={imgBig} setOpenImgBig={setOpenImgBig} />}
    </>
  );
};
