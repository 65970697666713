import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //Получаем из локального хранилища массив объектов или, если его нет, то присваеваем пустой массив.
  item: JSON.parse(localStorage.getItem("item")) || [],
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    // Добавление в корзину покупок
    addItem(state, action) {
      const findItem = state.item.find((item) => item.id === action.payload.id);
      if (!findItem) {
        state.item.push({
          ...action.payload,
          count: action.payload.minCount,
          totalPrice: action.payload.price * action.payload.minCount,
        });
        localStorage.setItem("item", JSON.stringify(state.item));
        localStorage.setItem(`toggle ${action.payload.id}`, true);
      }
    },

    //Замена продукта в корзине
    removeItem(state, action) {
      const findIndex = state.item.findIndex(
        (item) => item.id === action.payload.id
      );
      state.item[findIndex] = action.payload;
      localStorage.setItem("item", JSON.stringify(state.item));
    },

    //Удаление из корзины покупок
    deleteItem(state, action) {
      state.item = state.item.filter((item) => item.id !== action.payload);
      localStorage.setItem("item", JSON.stringify(state.item));
      localStorage.setItem(`toggle ${action.payload}`, "");
    },

    //Очистка корзины покупок
    clearBasket(state) {
      state.item = [];
      localStorage.clear();
    },

    //Увеличение кол-ва продуктов в корзине
    increaseCount(state, action) {
      const findItem = state.item.find((item) => item.id === action.payload);
      findItem.count += findItem.stepCount;
      findItem.totalPrice = findItem.price * findItem.count;
      localStorage.setItem("item", JSON.stringify(state.item));
    },

    //Уменьшение кол-ва продуктов в корзине
    decreaseCount(state, action) {
      const findItem = state.item.find((item) => item.id === action.payload);
      if (findItem.count > findItem.minCount) {
        findItem.count -= findItem.stepCount;
        findItem.totalPrice = findItem.price * findItem.count;
      }
      localStorage.setItem("item", JSON.stringify(state.item));
    },
  },
});

export const {
  addItem,
  removeItem,
  deleteItem,
  clearBasket,
  increaseCount,
  decreaseCount,
} = basketSlice.actions;
export default basketSlice.reducer;
