import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItem,
  deleteItem,
  increaseCount,
  decreaseCount,
} from "../../redux/slices/basketSlice";

import "./style.css";

export const BasketProduct = ({
  id,
  nameProduct,
  img,
  price,
  unit,
  groupProduct,
  decorList,
  discount,
}) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.item.item);
  const findItem = item.find((item) => item.id === id);

  const [open, setOpen] = React.useState(false);

  const refBtn = React.useRef(null);

  const { count, totalPrice, priceDecor } = findItem;

  //Закрытие выпадающего списка, если клик был вне списка
  React.useEffect(() => {
    if (!open) return;
    const outSideClick = (e) => {
      if (!refBtn.current.contains(e.target)) setOpen(false);
    };
    document.addEventListener("click", outSideClick);
    return () => {
      document.removeEventListener("click", outSideClick);
    };
  });

  //Добавляем оформление торта в корзину

  const clickSelectDecor = (i, e) => {
    e.stopPropagation();

    for (let decor of findItem.decor) {
      if (decor.dataDecor === decorList[i].dataDecor) {
        setOpen(false);
        return;
      }
    }

    dispatch(
      removeItem({
        ...findItem,
        decor: [...findItem.decor, decorList[i]],
        priceDecor: priceDecor + decorList[i].priceDecor,
      })
    );
    setOpen(false);
  };

  //Удаляем оформление торта

  const clickDeleteDecor = (del, e) => {
    e.stopPropagation()

    dispatch(
      removeItem({
        ...findItem,
        decor: [...findItem.decor.filter((decor) => decor.dataDecor !== del.dataDecor)],
        priceDecor: findItem.priceDecor - del.priceDecor,
      })
    )
  }

  return (
    <div className="basket__container">
      <div
        className={decorList ? "basket__product" : "basket__product nondecor"}
      >
        <div className="product__item">
          <div className="product__img">
            <img src={img} alt="Фото торта" />
          </div>
          <div className="product__group">
            <div className="product__name">
              {groupProduct} <br />
              {nameProduct}
            </div>
            <button
              className="product__name-btn"
              onClick={() => dispatch(deleteItem(id))}
            >
              <div className="product__svg">
                <svg viewBox="0 0 21 24">
                  <path d="M18.4732 6.63551H2.52679C1.68423 6.63551 1 5.95156 1 5.10934C1 4.26711 1.68423 3.58316 2.52679 3.58316H18.4732C19.3158 3.58316 20 4.26711 20 5.10934C20 5.95156 19.3158 6.63551 18.4732 6.63551Z" />
                  <path d="M14.6166 3.5832H6.77344V1.74048C6.77344 1.32785 7.10707 1 7.51421 1H13.8702C14.283 1 14.6109 1.3335 14.6109 1.74048V3.5832H14.6166Z" />
                  <path d="M16.2677 22.8752L5.18435 23.0052C4.64149 23.0109 4.1778 22.6039 4.12125 22.0612L2.52661 6.63556H18.5861L17.3025 21.9086C17.2573 22.4513 16.8105 22.8639 16.2677 22.8752Z" />
                  <path d="M6.64917 9.25266L7.25988 20.3203" />
                  <path d="M10.7035 9.06613L10.5735 20.1507" />
                  <path d="M14.7524 9.07181L14.1643 20.1394" />
                </svg>
                <p className="product__svg-text">Удалить</p>
              </div>
            </button>
          </div>
        </div>
        <div className="product__count_block">
          <div>Количество</div>
          <div className="product__count">
            <div className="count__title">
              {unit === "кг." ? count.toFixed(1) : count} {unit}
            </div>
            <div className="count__wrap">
             

              <button onClick={() => dispatch(decreaseCount(id))}>
                <svg className="count__minus" viewBox="0 0 25 25">
                  <path d="M6.78589 12.5H18.2145" />
                  <circle cx="12.5" cy="12.5" r="12" />
                </svg>
              </button>
              
              <button onClick={() => dispatch(increaseCount(id))}>
                <svg className="count__plus" viewBox="0 0 25 25">
                  <path d="M8.42847 12H17.5713" />
                  <path d="M13 7.42859V16.5714" />
                  <circle cx="12.5" cy="12.5" r="12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="product__price_wrap">
          <b>Стоимость за {unit}</b>
          {discount > 0 && (
            <p className="product__discount">(cкидкa {discount}%)</p>
          )}
          <p className="product__price">{price.toLocaleString()} руб.</p>
        </div>

        {decorList && (
          <>
            <div className="wrap__decor">
              <div className="product__decor">
                <button
                  ref={refBtn}
                  className={
                    open ? "decor__btn decor__btn_active" : "decor__btn"
                  }
                  onClick={() => setOpen(!open)}
                >
                  <b>Выберите оформление торта</b>
                </button>
                <ul
                  className={
                    open ? "decor__list decor__list-active" : "decor__list"
                  }
                >
                  {decorList.map((decor, i) => (
                    <li
                      key={i}
                      className="decor__list-item"
                      onClick={(e) => clickSelectDecor(i, e)}
                    >
                      {decor ? decor.dataDecor : decorList[0].dataDecor}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <ul className="appDecor__list-item">
                  {findItem.decor.map((decor, i) => (
                    <div key={i} className="appDecor__list_wrap" onClick = {(e)=> clickDeleteDecor(decor, e)}>
                      <li>
                        {decor.dataDecor}
                        {decor.priceDecor > 0
                          ? ` (${decor.priceDecor} руб.)`
                          : ""}
                      </li>
                      <div className="appDecor__close_icon">
                        <svg
                          viewBox="0 0 16 18"
                        >
                          <line
                            y1="-0.5"
                            x2="21.0241"
                            y2="-0.5"
                            transform="matrix(0.70069 -0.713466 0.70069 0.713466 1 16.3223)"
                          />
                          <line
                            y1="-0.5"
                            x2="21.0241"
                            y2="-0.5"
                            transform="matrix(-0.70069 -0.713466 0.70069 -0.713466 16 16.3223)"
                          />
                        </svg>
                      </div>
                      
                    </div>
                  ))}
                </ul>
              </div>
            </div>

            <div className="decor__price">
              <b>Стоимость оформления</b>
              <br />
              {priceDecor} руб.
            </div>
          </>
        )}
        <div className="count__totalPrice">
          <p>
            <b>Итого:</b>
          </p>
          <p className="totalPrice__summ">
            {(totalPrice + findItem.priceDecor).toLocaleString()} руб.
          </p>
        </div>
      </div>
    </div>
  );
};
