import React from "react";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenuActive } from "../../redux/slices/menuActiveSlice";
import { mainPage } from "../../dataMain";

import "./style.css";

const MainPageItem = ({ item }) => {
  const dispatch = useDispatch();
  window.scrollTo(0, 0);
  const { id, href, img, nameProduct, fullName } = item;

  return (
    <Link to={href}>
      <li
        className="main__card"
        key={href}
        onClick={() => dispatch(setMenuActive(id))}
      >
        <img className="main__card-img" src={img} alt={nameProduct}></img>
        {fullName}
      </li>
    </Link>
  );
};

export const MainPage = () => {
  return (
    <div className="container wrap">
      <div className="main">
        <div className="main__items">
          {mainPage.map((item, index) => (
            <MainPageItem key={index} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
