//Оформление бенто тортов
import welcome from '../img/item/Оформление тортов/Бенто/Welcome.jpg'
import drink from '../img/item/Оформление тортов/Бенто/А не бухнуть.jpg'
import game from '../img/item/Оформление тортов/Бенто/Девушка игрушка.jpg'
import autumn from '../img/item/Оформление тортов/Бенто/Девушка осень.jpg'
import grandpa from '../img/item/Оформление тортов/Бенто/Деду.jpg'
import live from '../img/item/Оформление тортов/Бенто/Живи в кайф.jpg'
import ilgiz from '../img/item/Оформление тортов/Бенто/Ильгизу.jpg'
import mers from '../img/item/Оформление тортов/Бенто/Мерс.jpg'
import nikah from '../img/item/Оформление тортов/Бенто/Никах.jpg'
import heart from '../img/item/Оформление тортов/Бенто/Руки сердце.jpg'
import diana from '../img/item/Оформление тортов/Бенто/С ДР Диана.jpg'
import genya from '../img/item/Оформление тортов/Бенто/С ДР Женя.jpg'
import semen from '../img/item/Оформление тортов/Бенто/С ДР Семен.jpg'
import timur from '../img/item/Оформление тортов/Бенто/С ДР Тимур.jpg'
import enge from '../img/item/Оформление тортов/Бенто/С ДР Энже.jpg'
import dr_girl from '../img/item/Оформление тортов/Бенто/С ДР девушка.jpg'
import dr_mam from '../img/item/Оформление тортов/Бенто/С ДР мама.jpg'
import dr_me from '../img/item/Оформление тортов/Бенто/С ДР меня.jpg'
import bear_green from '../img/item/Оформление тортов/Бенто/С ДР мишка зел.jpg'
import bear_red from '../img/item/Оформление тортов/Бенто/С ДР мишка.jpg'
import dr_dear from '../img/item/Оформление тортов/Бенто/С ДР родная.jpg'
import dr_flowers from '../img/item/Оформление тортов/Бенто/С ДР цветы.jpg'
import dr from '../img/item/Оформление тортов/Бенто/С ДР.jpg'
import wedding from '../img/item/Оформление тортов/Бенто/С днем свадьбы.jpg'
import supermen from '../img/item/Оформление тортов/Бенто/Супермен.jpg'
import supermen2 from '../img/item/Оформление тортов/Бенто/Супермен2.jpg'
import may_all from '../img/item/Оформление тортов/Бенто/Теперь можно все.jpg'
import year_18 from '../img/item/Оформление тортов/Бенто/Уже 18.jpg'
import uraza from '../img/item/Оформление тортов/Бенто/Ураза.jpg'
import summer from '../img/item/Оформление тортов/Бенто/Это лето.jpg'
import guess from '../img/item/Оформление тортов/Бенто/Я загадала.jpg'
import bento32 from '../img/item/Оформление тортов/Бенто/bento32.jpg'
import bento33 from '../img/item/Оформление тортов/Бенто/bento33.jpg'
import bento34 from '../img/item/Оформление тортов/Бенто/bento34.jpg'
import bento35 from '../img/item/Оформление тортов/Бенто/bento35.jpg'
import bento36 from '../img/item/Оформление тортов/Бенто/Скорпион.jpg'


export const bentoDecor = [
  {
    name: 'Оформление бенто',
    descDecor: 'поз.1',
    img: welcome,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.2',
    img: drink,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.3',
    img: game,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.4',
    img: autumn,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.5',
    img: grandpa,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.6',
    img: live,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.7',
    img: ilgiz,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.8',
    img: mers,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.9',
    img: nikah,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.10',
    img: heart,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.11',
    img: diana,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.12',
    img: genya,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.13',
    img: semen,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.14',
    img: timur,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.15',
    img: enge,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.16',
    img: dr_girl,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.17',
    img: dr_mam,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.18',
    img: dr_me,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.19',
    img: bear_green,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.20',
    img: bear_red,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.21',
    img: dr_dear,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.22',
    img: dr_flowers,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.23',
    img: dr,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.24',
    img: wedding,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.25',
    img: supermen,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.26',
    img: supermen2,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.27',
    img: may_all,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.28',
    img: year_18,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.29',
    img: uraza,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.30',
    img: summer,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.31',
    img: guess,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.32',
    img: bento32,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.33',
    img: bento33,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.34',
    img: bento34,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.35',
    img: bento35,
  },
  {
    name: 'Оформление бенто',
    descDecor: 'поз.36',
    img: bento36,
  },
];